import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';


const PrivacyPolicy = () => {

    return (
        <>
            <SEO title="Privacy Policy" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                title="Privacy Policy"
                page="Privacy Policy"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                <div className="section-title">
                                    <h5 className="title">This Privacy policy was published on September 10th, 2021.</h5>
                                </div>
                                <h4>GDPR compliance</h4>
                                <p>The General Data Protection Regulation (GDPR) is a regulation implemented by the European Union (EU) to protect the privacy and personal data of EU citizens. If your business collects, processes, or stores the personal data of EU citizens, you must comply with the GDPR.
                                </p>
                                <h4>About Datalytics AI</h4>
                                <p>At Datalytics AI, we don’t just develop products, we build, accelerate, and support your business. We take your idea, vision, or business goal and transform it into a web app or an enterprise digital solution. <a href="mailto:info@datalyticsai.com">info@datalyticsai.com</a> </p>
                                <h4>When we collect personal data about you</h4>
                                <ul>
                                    <li>Email is a crucial channel in any marketing.</li>
                                    <li>Curious what to say? How to say it?</li>
                                    <li>Whether you’re kicking off a new campaign.</li>
                                    {/* <li>Habitasse per feugiat aliquam luctus accumsan curae</li> */}
                                </ul>
                                <h4>Why we collect and use personal data</h4>
                                <p className="mb--20">Personal data is often collected to provide services to individuals. For example, when you sign up for a social media account or make a purchase online, you are typically asked to provide personal information such as your name, address, and email address. This information is used to provide the service you requested.</p>
                                <ul>
                                    <li>Companies collect personal data to understand their customers and improve their products and services. By analyzing customer data, companies can gain insights into what their customers want and how they use their products. This can help them make changes to their products and services that better meet customer needs.</li>
                                    <li>Personal data is often used to personalize experiences for individuals. For example, websites and apps may use personal data to recommend content or products that are relevant to the individual's interests.</li>
                                    <li>Companies may be required by law to collect and use personal data in certain circumstances. For example, financial institutions are required to collect personal data to comply with anti-money laundering regulations.</li>
                                    <li>Personal data is often used for research purposes. Researchers may collect and analyze personal data to better understand human behavior and social trends.</li>
                                    <li>It's important to note that the collection and use of personal data must be done in a responsible and ethical manner, and individuals have the right to control how their personal data is used.</li>
                                </ul>
                                <h4>Type of personal data collected</h4>
                                <p>Personal data is any information that relates to an identified or identifiable individual. The type of personal data that may be collected can vary depending on the purpose of the data collection, but some common types of personal data include:

Name </p>
                                <h4>Information we collect automatically</h4>
                                <p>Websites and digital services often collect certain types of information automatically when users interact with them.</p>
                                <h4>The use of cookies and web beacons</h4>
                                <p className="mb--20">We may log information using "cookies." Cookies are small data files stored on your hard drive by a website. Cookies help us make our Site and your visit better.</p>
                                <p className="mb--20">May log information using digital images called web beacons on our Site or in our emails.</p>
                                <p>This information is used to make our Site work more efficiently, as well as to provide business and marketing information to the owners of the Site, and to gather such personal data as browser type and operating system, referring page, path through site, domain of ISP, etc. for the purposes of understanding how visitors use our Site. Cookies and similar technologies help us tailor our Site to your personal needs, as well as to detect and prevent security threats and abuse. If used alone, cookies and web beacons do not personally identify you.</p>
                                <h4>How long we keep your data</h4>
                                <p className="mb--20">We store personal data for as long as we find it necessary to fulfill the purpose for which the personal data was collected, while also considering our need to answer your queries or resolve possible problems. This helps us to comply with legal requirements under applicable laws, to attend to any legal claims/complaints, and for safeguarding purposes.</p>
                                <p>We store personal data for as long as we find it necessary to fulfill the purpose for which the personal data was collected, while also considering our need to answer your queries or resolve possible problems. This helps us to comply with legal requirements under applicable laws, to attend to any legal claims/complaints, and for safeguarding purposes.</p>
                                <h4>Your rights to your personal data</h4>
                                <p>We store personal data for as long as we find it necessary to fulfill the purpose for which the personal data was collected, while also considering our need to answer your queries or resolve possible problems. This helps us to comply with legal requirements under applicable laws, to attend to any legal claims/complaints, and for safeguarding purposes.</p>
                                {/* <h4>Hotjar’s privacy policy</h4>
                                <p>Pellentesque vestibulum venenatis iaculis. Aliquam viverra sodales ultrices. Quisque sed purus id massa consequat consectetur eu vel lorem. Maecenas lectus velit, cursus quis orci non, laoreet hendrerit mi. Nulla vitae ipsum fringilla, placerat metus eu, malesuada velit. Quisque viverra risus ex. Aenean commodo vestibulum efficitur. Nullam ligula orci, aliquet sed luctus vel, luctus vel dui. Sed pulvinar, ipsum at mattis imperdiet, diam augue tempor diam, sed porttitor odio elit ut ante. In posuere mi at mi pellentesque ornare sit amet gravida nisi. Praesent ac blandit odio. Curabitur iaculis ante elit, et imperdiet leo mollis at.</p> */}
                                <h4>Changes to this Privacy Policy</h4>
                                <p>Integer eu ornare lectus, ornare ullamcorper tellus. Morbi in urna a justo dignissim luctus. Nam sagittis ante ut lorem feugiat, sed consectetur ligula lacinia. Vestibulum quis mauris sed lectus pretium dictum sed vitae orci. Vestibulum facilisis facilisis mauris non maximus. Nam tristique ipsum egestas, suscipit orci sit amet, rutrum ante. Proin in felis pellentesque, ullamcorper enim vel, molestie ipsum. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CtaLayoutOne />
            <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default PrivacyPolicy;