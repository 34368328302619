import React from 'react';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import FormTwo from '../component/contact/FormTwo';
import SectionTitle from '../elements/section-title/SectionTitle';
import ContactLocation from '../component/contact/ContactLocation';
import emailjs from'emailjs-com'
const Contact = () => {
 
    function sendEmail(e){
        e.preventDefault();
        emailjs.sendForm('service_1zyfxip','template_4ywric5',e.target,"wyrCT1nUdcwCVVbtZOZ-l"
        ).then(res=>{console.log(res);
          
        }).catch(err=> console.log(err));
      
  
      }
   
    return (
        <>
            <SEO title="Blog Grid" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                title="Our Expertise"
                page="Expert"
                />

<div className="container expertise">
                <div className="section-heading row align-items-center">
                    
                    <div >
                        <div className='d-flex  justify-content-center ' style={{marginTop:"40px" }}>
                        {/* <h2>Our Expertise</h2> */}
                            {/* <div className="section-heading heading-left mb-0">
                                <span className="subtitle">About Us</span>
                                <h2 className="title mb--40">We Are Building Solutions That Matter</h2>
                                <p>At Datalytics AI, we don’t just develop products, we build, accelerate, and support your business. We take your idea, vision, or business goal and transform it into a web app or an enterprise digital solution. </p>
                            </div> */}
                        </div>
                        <div  className='d-flex justify-content-center mt-8' style={{marginTop:"40px"}}>
                            
                        <img src={process.env.PUBLIC_URL + "/images/expertise/expert1.png"} alt="thumbnail" />                        </div>
                    </div>

                  
                </div>
            </div>

            <FooterOne parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60" />
            </main>
        </>
    )
}

export default Contact;