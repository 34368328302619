import React from 'react';
import TestimonialData from "../../data/testimonial/TestimonialData.json";

const TestimonialItem = ({colSize, itemShow, testimonialData, layoutStyle}) => {
    const allData = TestimonialData;

    return (
        <>
           {/* {allData.slice(0, itemShow=5).map((data, index) => (
                <div >
                    <div className="testimonial ">
                    <div className="author-info">
                           
                            <div className="content" style={{display:'flex', flexDirection:'column'}}>
                                <span className="name">{data.authorname}</span>
                                <span className="designation" style={{color:'red'}}>{data.authordesig}</span>
                            </div>
                        </div>
                       
                        <p style={{width:"60%"}} className='mt-3 description'>{data.description}</p>
                      
                    </div>
                </div>
            ))} */}
         
                <div class="containertestimonial">
                    <div class="testimonial">
                        <div class="author-info">
                            <div class="content">
                                <span class="name">Aaron Jorgensen</span>
                                <span class="designation">Canada</span>
                            </div>
                        </div>
                        <p class="description" style={{color:'black'}}>Datalytics AI Team did an excellent job and were very thorough. Their effort, communication and dedication to the project definitely set them apart and I will be working with them again for future projects! I highly recommend Datalytics AI!</p>
                    </div>
                    <div class="testimonial">
                        <div class="author-info">
                            <div class="content">
                                <span class="name">Fransisca</span>
                                <span class="designation">United States</span>
                            </div>
                        </div>
                        <p class="description" style={{color:'black'}}>I recently had the pleasure of working with Datalytics AI Team on a project related to elastic search fscrawler, and I have to say, their performance was outstanding.Overall, I would highly recommend Datalytics AI for any projects related to AI and Web Development Thank you, Datalytics AI Team, for your outstanding performance and for going above and beyond to ensure the success of this project.</p>
                    </div>
                    <div class="testimonial">
                        <div class="author-info">
                            <div class="content">
                                <span class="name">Ian Ito</span>
                                <span class="designation">United States</span>
                            </div>
                        </div>
                        <p class="description" style={{color:'black'}}>Had a great time working with Datalytics AI Team. They are very responsive and did my deployment on AWS and integrations to get the job done. Will work with them again!</p>
                    </div>
                    <div class="testimonial">
                        <div class="author-info">
                            <div class="content">
                                <span class="name">Dennis Benedetto</span>
                                <span class="designation">United States</span>
                            </div>
                        </div>
                        <p class="description" style={{color:'black'}}>Datalytics AI is Thorough, skilled and attentive to the project’s needs beyond what was spec’d.</p>
                    </div>
                    <div class="testimonial">
                        <div class="author-info">
                            <div class="content">
                                <span class="name">David Garcia</span>
                                <span class="designation">United States</span>
                            </div>
                        </div>
                        <p class="description" style={{color:'black'}}>Datalytics AI Offshore Team Seamlessly Integrated AI Automation To our platform, which increased our productivity in terms of analysing latest trends on social media</p>
                    </div>
                </div>
          
                
        </>
    )
}

export default TestimonialItem;