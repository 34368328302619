import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import CounterUp from '../../component/counterup/CounterUp';


const CounterUpOne = () => {
    return (
        <div className="section section-padding bg-color-dark">
            <div className="container">
            <SectionTitle 
                    subtitle="Featured Case Study"
                    title="Design startup movement"
                    description=" We build customized solutions for businesses  to compete, seize
                    new opportunities, overcome business challenges in some of the world’s
                    leading marketplaces."
                    textAlignment="heading-light"
                    textColor=""
                />
                <div className="row flex justify-content-center">
                    <CounterUp colSize="col-lg-4 col-6" layoutStyle="" evenTopMargin="" />
                </div>
            </div>
        </div>
    )
}


export default CounterUpOne;