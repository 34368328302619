import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Link } from 'react-router-dom';


const  BannerOne = () => {
    return (
        <div className="banner banner-height banner-style-1 w-100" style={{paddingTop:'140px'}}>
            <div className="container w-100 ">
                <div style={{
                    display: 'flex',
                    alignItems: "center",
                    justifyContent: 'center',
                    flexDirection:'row',
                }}>
                    <div className="col-lg-8 w-70">
                        <div className="banner-content" >
                            <AnimationOnScroll animateIn="fadeInUp" animateOnce={true} delay={100}>
                                <h1 style={{fontSize:'70px', fontWeight:'600' }}>Smart Choices, Smart Future: Empower Your Business with AI-Driven Solutions!</h1>
                                <span className="subtitle">Businesses thrive with the right tech partner. We build bespoke software solutions at record speed, ensuring exceptional quality and performance. Trusted by industry leaders, we become an extension of your team, empowering you to innovate and win. </span>
                                <Link to={process.env.PUBLIC_URL + "/contact"} className="axil-btn btn-fill-primary btn-large mb-4">Get Started</Link>
                            </AnimationOnScroll>
                            </div>
                    </div>
                    <div className="col-lg-5 w-40" >
                        <div className="banner-thumbnail">
                              {/* <AnimationOnScroll animateIn="zoomIn" duration={2} delay={300} animateOnce={true}>
                                <div className="large-thumb">
                                    <img src={process.env.PUBLIC_URL + "/images/banner/window1.png"} alt="Laptop" />
                                </div>
                            </AnimationOnScroll> */}
                            <AnimationOnScroll animateIn="zoomIn" duration={2} delay={300} animateOnce={true}>
                            <div className="banner-thumbnail">
                                <img src={process.env.PUBLIC_URL + "/images/banner/main1.png"} alt="Laptop" />
                            </div>
                            </AnimationOnScroll>
                            <ul className="list-unstyled shape-group">
                                {/* <li className="shape shape-1">
                                    <AnimationOnScroll animateIn="slideInLeft" duration={1} delay={800} animateOnce={true}>
                                        <img src={process.env.PUBLIC_URL + "/images/banner/chat-group.png"} alt="chat" />
                                    </AnimationOnScroll>
                                </li>  */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="list-unstyled shape-group-21">
                {/* <li className="shape shape-1">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-39.png"} alt="Bubble" />
                </li> */}
                {/* <li className="shape shape-2">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-38.png"} alt="Bubble" />
                </li> */}
                <li className="shape shape-3">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
                </li>
                <li className="shape shape-4">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
                </li>
                <li className="shape shape-5">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
                </li>
                <li className="shape shape-6">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-40.png"} alt="Bubble" />
                </li>
                <li className="shape shape-7">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-41.png"} alt="Bubble" />
                </li>
            </ul>
        </div>
    )
}

export default BannerOne;