import React from "react";
import { Link } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaLinkedin,
  FaPhone,
  FaInstagram,
  FaInfoCircle, // Add the necessary icon for "Contact Information"
  FaClock // Add the necessary icon for "We're Available 24/7. Call Now."
} from "react-icons/fa";

const OffcanvasMenu = ({ offcanvasShow, offcanvasHide }) => {
  return (
    <Offcanvas
      show={offcanvasShow}
      onHide={offcanvasHide}
      placement="end"
      className="header-offcanvasmenu"
    >
      <Offcanvas.Header closeButton></Offcanvas.Header>
      <Offcanvas.Body>
        <div className="">
          <div>
            <div className="contact-info-wrap">
              <div className="contact-inner">
                <address className="address">
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <img src="\images\location.png" alt="location" style={{ marginLeft:'-10px',marginBottom:'14px'}} />
                    <span className="title">Our Locations</span>
                  </div>
                  <span className="title">Pakistan</span>
                  <p>Plaza Plot No.34, I-11/3, Islamabad</p>
                  <span className="title mt-3">United States</span>
                  <p>834 32nd Ave, Seattle, WA, United States</p>
                  <span className="title mt-3">Canada</span>
                  <p>658 Tournier St, Windsor, ON, Canada</p>
                </address>
                <address className="address">
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <img src="\images\phone.png" alt="location" style={{ marginLeft:'-10px',marginRight :'2px',marginBottom:'14px'}} />
                    <span className="title">We're Available 24/7. Call Now.</span>
                  </div>
                  <a href="tel:8884562790" className="tel">
                  
                    USA: +1 313-266-9545
                  </a>
                  <a href="tel:12125553333" className="tel">
                  
                    Canada: +1 672 588-1089
                  </a>
                  <a href="tel:12125553333" className="tel">
                   
                    UK: +44 7424115829
                  </a>
                </address>
              </div>
              <div className="contact-inner">
                <h5 className="title">Find us here</h5>
                <div className="contact-social-share">
                  <ul className="social-share list-unstyled">
                    <li>
                      <a
                        href="https://www.facebook.com/profile.php?id=61555184161642"
                        target="_blank"
                        className="icon"
                      >
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/DatalyticsAi"
                        target="_blank"
                        className="icon"
                      >
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/datalytics-ai/"
                        target="_blank"
                        className="icon"
                      >
                        <FaLinkedin />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/datalytics_ai/"
                        target="_blank"
                        className="icon"
                      >
                        <FaInstagram />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default OffcanvasMenu;
