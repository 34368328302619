import React from 'react';
import Expertiseimg from './Expertiseimg';
// import FormTwo from '../contact/FormTwo';

const Expertise = () => {
    return (
        <section className=" " >
            <div className="container expertise">
                <div className="section-heading row align-items-center">
                    
                    <div >
                        <div className='d-flex  justify-content-center ' style={{marginTop:"40px" }}>
                        <h2>Our Expertise</h2>
                            {/* <div className="section-heading heading-left mb-0">
                                <span className="subtitle">About Us</span>
                                <h2 className="title mb--40">We Are Building Solutions That Matter</h2>
                                <p>At Datalytics AI, we don’t just develop products, we build, accelerate, and support your business. We take your idea, vision, or business goal and transform it into a web app or an enterprise digital solution. </p>
                            </div> */}
                        </div>
                        <div  className='d-flex justify-content-center mt-8' style={{marginTop:"40px"}}>
                        {/* <Logo
                                limage="/images/updatedlogo1.svg"
                                dimage="/images/logodark.svg"
                                simage="/images/updatedlogo1.svg"
                                /> */}
                                {/* <Expertiseimg
                                 limage="/images/expertise/expert1.png"
                                 dimage="/images/expertise/expertdark.png"
                                 simage="/images/expertise/expert1.png"
                                /> */}
                        <img src={process.env.PUBLIC_URL + "/images/expertise/expert1.png"} alt="thumbnail" />
                        
                        </div>
                    </div>

                  
                </div>
            </div>
            <ul className="shape-group-6 list-unstyled">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-7.png"} alt="Bubble" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="line" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="line" /></li>
            </ul>
        </section>
    )
}

export default Expertise;