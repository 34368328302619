import React from 'react';
import { Link } from 'react-router-dom';
import ServiceData from "../../data/service/ServiceMain.json";
import { slugify } from '../../utils';


const AllData = ServiceData;

const ServicePropOne = ({colSize, serviceStyle, itemShow, marginTop}) => {

	const topMargin = (index) => {
		if (marginTop === "yes") {
			if (index === 0) {
				return "mt--200 mt_md--0";
			} else if (index === 1) {
				return "mt--100 mt_md--0";
			}else {
				return "mt--0";
			}
		}else {
			return "";
		}
	}

    return (
		<>
			{AllData.slice(0, itemShow).map((data, index) => (
				<div className={`${colSize} ${topMargin(index)}`} key={index} >
					<div style={{
  backgroundColor: 'rgba(255, 255, 255, 0.4)',
  marginBottom: '40px',
  marginRight: '40px',
  borderRadius: '20px',
  gap: '10px',
  padding: '50px',
  height: '90%'
}}>

						<div className='thumbnail'>
							<img src={process.env.PUBLIC_URL + data.image} alt="icon" />
						</div>
						<div className="content">
							<h5 className="title" style={{color:'black'}}> 
								<Link to={process.env.PUBLIC_URL + `/service-details/${slugify(data.title)}`}>{data.title}</Link>
							</h5>
							<p  style={{color:'black'}}>{data.description}</p>

						</div>
					</div>
			 	</div>
			))}
		</>
    )
}

export default ServicePropOne;