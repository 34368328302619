import React from 'react';
import TestimonialData from "../../data/testimonial/TestimonialData.json";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const allData = TestimonialData;


const TestimonialItem = ({colSize, itemShow}) => {
    
 
    
    return (
        <>
        <div class="container" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
    <div class="testimonial">
        <div class="author-info">
            <div class="content">
                <span class="name">Aaron Jorgensen</span>
                <span class="designation">Canada</span>
            </div>
        </div>
        <p class="description">Datalytics AI Team did an excellent job and were very thorough. Their effort, communication and dedication to the project definitely set them apart and I will be working with them again for future projects! I highly recommend Datalytics AI!</p>
    </div>
    <div class="testimonial">
        <div class="author-info">
            <div class="content">
                <span class="name">Fransisca</span>
                <span class="designation">United States</span>
            </div>
        </div>
        <p class="description">I recently had the pleasure of working with Datalytics AI Team on a project related to elastic search fscrawler, and I have to say, their performance was outstanding.Overall, I would highly recommend Datalytics AI for any projects related to AI and Web Development Thank you, Datalytics AI Team, for your outstanding performance and for going above and beyond to ensure the success of this project.</p>
    </div>
    <div class="testimonial">
        <div class="author-info">
            <div class="content">
                <span class="name">Ian Ito</span>
                <span class="designation">United States</span>
            </div>
        </div>
        <p class="description">Had a great time working with Datalytics AI Team. They are very responsive and did my deployment on AWS and integrations to get the job done. Will work with them again!</p>
    </div>
</div>

       
      
        </>
        
    )
}

export default TestimonialItem;