import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Alert from "react-bootstrap/Alert";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const Result = () => {
  return (
    <Alert variant="success" className="success-msg">
      Your Message has been successfully sent.
    </Alert>
  );
};

const FormTwo = () => {
  const form = useRef();
  const [result, showresult] = useState(false);
  const [phone, setPhone] = useState("");
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_fwhjbm2",
        "template_8fjmln6",
        e.target,
        "Jyr0JBo3Czax1GNE3"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    form.current.reset();
    showresult(true);
  };

  setTimeout(() => {
    showresult(false);
  }, 5000);

  return (
    <form ref={form} onSubmit={sendEmail} className="axil-contact-form">
      <div className="form-group">
        <label>Name</label>
        <input
          style={{marginLeft:"10px", borderColor:"#D3D3D3" ,backgroundColor:"white"  ,color:"black"}}
          type="text"
          className="form-control"
          name="contact_name"
          required
        />
      </div>
      <div className="form-group" >
        <label>Email</label>
        <input
          style={{ marginLeft:"10px",borderColor:"#D3D3D3" ,backgroundColor:"white"  ,color:"black"}}
          type="email"
          className="form-control"
          name="contact_email"
          pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
          id="emailInput" aria-describedby="emailHelp"
          required
        />
          <div class="invalid-feedback">
      Please enter a valid email address.
    </div>
      </div>
      <div className="form-group ">
        <label>Phone</label>
    
      
        {/* <PhoneInput
     
 
          placeholder="Enter phone number"
         
          country={"us"}
          value={phone}
          onChange={setPhone}
          inputProps={{
            name: "contact_phone",
            required: true,
          }}
        /> */}
        <PhoneInput
        className="dark:bg-black "
  country="us"
  value={phone}
  onChange={setPhone}
  inputProps={{
    name: "contact_phone",
    required: true,
    style: {
      height: "60px", 
      width: "100%",
      padding: "35px", 
      fontSize: "14px", 
      borderRadius: "15px",
      borderColor:"#D3D3D3",
      color:"black",
      textColor:"black",
      backgroundColor:"white",
      marginLeft:"10px"
    },
  }}
/>
        
        {/* <input type="tel" className="form-control" name="contact_phone"  id="phone"  required /> */}
      </div>
      <div className="form-group mb--40">
        <label>How can we help you?</label>
        <textarea
        style={{marginLeft:"10px", borderColor:"#D3D3D3"  ,backgroundColor:"white" ,color:"black"}}
          className="form-control"
          name="contact_message"
          rows="4"
        ></textarea>
      </div>
      <div className="form-group">
        <button
          type="submit"
          className="axil-btn btn-fill-primary btn-fluid btn-primary"
          name="submit-btn"
        >
          Submit
        </button>
      </div>
      <div className="form-group">{result ? <Result /> : null}</div>
    </form>
  );
};

export default FormTwo;
