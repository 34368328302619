import React from 'react';

const Data = [
    {
        image: "/images/brand/brain1.jpg"
    },
    {
        image: "/images/brand/writer.png"
    },
    {
        image: "/images/brand/fav.png"
    },
    {
        image: "/images/brand/mountain.jpg"
    },
    {
        image: "/images/brand/muviconm.png"
    },
    {
        image: "/images/brand/newton.png"
    },
    {
        image: "/images/brand/logo.png"
    },
    {
        image: "/images/brand/rolex.jpg"
    },
    {
        image: "/images/brand/saas.png"
    },
    {
        image: "/images/brand/testlio.png"
    },
    {
        image: "/images/brand/dobility.jpeg"
       
     
    },
    {
        
        image: "/images/brand/wire.png"
    },
    {
        image: "/images/brand/trusted.png"
    },
    {
        image: "/images/brand/autocontent.png"
    },
    
]


const BrandItem = () => {
    return (
        <>
            {Data.map((data, index) => (
                <div className="col-lg-3 col-6 " key={index}>
                    <div className="brand-grid">
                        <img src={process.env.PUBLIC_URL + data.image} alt="Brand" />
                    </div>
                </div>
            ))}
        </>
    )
}

export default BrandItem;