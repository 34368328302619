import React from 'react';
import { Link } from 'react-router-dom';
import TestimonialPropOne from './TestimonialPropOne';

const Testimonial = () => {
    return (
        <div className="row ">
           
              <div className='d-flex justify-content-center' style={{ marginTop:"100px"}}>
                <h4 style={{color:"#1981BA"}}>Testimonial</h4>
                
              </div>
              <div className='d-flex justify-content-center ' >
              <h3 >What Our <span  style={{ color:"#1981BA"}}> Clients </span> Say About  <span  style={{ color:"#1981BA"}}>Us</span>
              </h3>
              

              </div>
            
          <TestimonialPropOne/>
            <div className="input-group justify-content-center flex ">
            <Link to={process.env.PUBLIC_URL + "/testimonial"}>
            <button className="subscribe-btn" type="submit">View All</button>
            </Link>
                                   
                                </div>
            
        </div>
    )
}

export default Testimonial;