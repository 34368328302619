import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import ServicePropOne from '../component/service/ServicePropOne';
import AboutThree from '../component/about/AboutThree';
import CounterUpTwo from '../component/counterup/CounterUpTwo';


const Service = () => {


    return (
        <>
        <SEO title="Service" />

        <ColorSwitcher />
        <main className="main-wrapper">
            <HeaderOne />
            <BcrumbBannerOne 
                title="Best solutions for your business"
            paragraph ="At DatalyticsAI, we are committed to providing cutting-edge solutions to meet your unique needs. Our expert team specializes in delivering comprehensive services that drive innovation and efficiency. Explore our offerings to discover how we can empower your business with the latest in technology and intelligence."
                
                styleClass="bann-thumbnail"
                mainThumb="/images/banner/career.jpg"
            />
            <CounterUpTwo />
            <div className="section section-padding bg-color-light">
                <div className="container">
                    <SectionTitle 
                        subtitle="What We Can Do For You"
                        title="Services we can <br> help you with"
                        description=""
                        textAlignment="heading-left"
                        textColor=""
                    />
                    <div className="row w-100">
                        <ServicePropOne colSize="col-lg-5 col-md-7" serviceStyle="service-style-2" itemShow="6" marginTop="no" />
                    </div>
                </div>
            </div>

            <AboutThree />
            
            <CtaLayoutOne />
            <FooterOne parentClass="parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60 />
        </main>
        </>
    )
}

export default Service;