import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';


const TermsOfUse = () => {

    return (
        <>
            <SEO title="Terms Of Use" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                title="Terms Of Use"
                page="Terms Of Use"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                <p>Terms and conditions are the rules and regulations that govern the relationship between two parties, such as a business and its customers or users. These terms and conditions set out the terms of use or sale, and they provide a legal framework for the relationship.</p>
                                <h4>Important details</h4>
                                <p>Terms and conditions typically include important details such as the rights and responsibilities of each party, payment terms, warranties and disclaimers, limitations of liability, and dispute resolution procedures. They are often presented in a document that users or customers are required to read and agree to before using a service or purchasing a product.
                                </p>
                                <h4>About Datalytics AI</h4>
                                <p>It is important to carefully read and understand the terms and conditions before agreeing to them, as they can have significant legal implications. <a href="mailto:info@datalyticsai.com">info@datalyticsai.com</a> </p>
                                <h4>Rules, Guidelines, and Agreements</h4>
                                <p>"Terms and conditions" refers to the rules, guidelines, and agreements that govern the use of a product, service, or platform. These terms and conditions set out the rights and responsibilities of both the provider of the service or product and the user or consumer.</p>
                                <h4>wide range of topics</h4>
                                <p>Terms and conditions typically cover a wide range of topics, including privacy policies, user conduct, intellectual property rights, liability, warranties, and dispute resolution procedures. They are legally binding and are designed to protect both the provider and the user by establishing clear expectations and procedures for using the product or service</p>
                                <h4>Users to read</h4>
                                <p>It is important for users to read and understand the terms and conditions of a product or service before using it, as failure to comply with the terms and conditions can lead to consequences such as account suspension or termination.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <CtaLayoutOne />
            <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default TermsOfUse;