import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import ProcessOne from "../component/process/ProcessOne";
import AboutThree from "../component/about/AboutThree";
import AboutFour from "../component/about/AboutFour";
import AboutFive from "../component/about/AboutFive";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";

const AboutUs = () => {
  return (
    <>
      <SEO title="About us" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BcrumbBannerOne
          title="We Are Building Solutions That Matter"
          paragraph="At Datalytics AI, we don’t just develop products, we build, accelerate, and support your business.Datalytics AI seems to be focused on providing comprehensive solutions for businesses rather than just developing standalone products. By building, accelerating, and supporting businesses, you're likely emphasizing a holistic approach to help companies achieve their goals and maximize their potential.Datalytics AI shows a commitment to assisting clients throughout their journey, whether it's through troubleshooting, training, or continuous improvement."
        //   description="Datalytics AI recognizes the need for ongoing improvement. You likely engage in continuous research and development to enhance your products, incorporating client feedback and staying up-to-date with the latest advancements. By consistently improving your offerings, you ensure that clients receive cutting-edge solutions that align with their evolving needs."
          styleClass="bann-thumbnail"
          mainThumb="/images/banner/banr.jpg"
        />

        <AboutFour />
        <AboutThree />
        <AboutFive />
        <ProcessOne />
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default AboutUs;
